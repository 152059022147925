function Hero() {
  return (
    <section className="hero-container">
      <h1>
        Bonjour, je m'appelle <span>Anthony</span>
        <br /> développeur front-end,
      </h1>
    </section>
  )
}

export default Hero
